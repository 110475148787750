import './Title.css'

const image = `${process.env.PUBLIC_URL}/Headline.png`

const Title: React.FC = () => {
  return (
    <div id="title_e_21">
      <div id="title_e_22">
        <div id="title_e_24">
          <p id="title_e_27">Frontend design made simple for developers</p>
          <p id="title_e_26">
            Get a professional UI without becoming a design expert. Start with
            professional components, adapt to your app, and export to code.
          </p>
          <a id="title_e_29" href="mailto:ben@repaint.com">
            <p id="title_e_28">Book a Demo</p>
          </a>
        </div>
        <img id="title_e_25" alt="Title" src={image} />
      </div>
    </div>
  )
}

export { Title }
