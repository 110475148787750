import './Navbar.css'

const logo = `${process.env.PUBLIC_URL}/RepaintLogoWhite.png`

const Navbar: React.FC = () => {
  return (
    <div id="nav_e_3">
      <div id="nav_e_4">
        <a href="https://repaint.com" id="nav_e_7">
          <img id="nav_e_6" alt="Repaint Logo" src={logo} />
          <p id="nav_e_5">Repaint</p>
        </a>
        <div id="nav_e_15">
          <p
            id="nav_e_8"
            onClick={() => {
              const featuresSection = document.getElementById('one_e_21')
              if (featuresSection) {
                featuresSection.scrollIntoView({ behavior: 'smooth' })
              }
            }}
          >
            Features
          </p>
          <a id="nav_e_12" href="mailto:ben@repaint.com">
            Contact
          </a>
          <div
            id="nav_e_18"
            onClick={() => {
              const ctaSection = document.getElementById('cta_e_21')
              if (ctaSection) {
                ctaSection.scrollIntoView({ behavior: 'smooth' })
              }
            }}
          >
            <p id="nav_e_13">Join Waitlist</p>
          </div>
          <a id="nav_e_20" href="mailto:ben@repaint.com">
            <p id="nav_e_19">Book a Demo</p>
          </a>
        </div>
      </div>
    </div>
  )
}

export { Navbar }
