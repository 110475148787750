import './SectionOne.css'

const image = `${process.env.PUBLIC_URL}/Section1.png`

const SectionOne: React.FC = () => {
  return (
    <div id="one_e_21">
      <div id="one_e_22">
        <img id="one_e_25" alt="Section 1" src={image} />
        <div id="one_e_39">
          <div id="one_e_40">
            <p id="one_e_41">1</p>
          </div>
        </div>
        <div id="one_e_24">
          <p id="one_e_27">Start from pre-built, professional components</p>
          <p id="one_e_26">
            No need to reinvent the wheel and design from scratch - Jump in with
            professional components and layouts like ShadCN.
          </p>
        </div>
        <div id="one_e_101"></div>
        <div id="one_e_102"></div>
      </div>
    </div>
  )
}

export { SectionOne }
