import './SectionTwo.css'

const image = `${process.env.PUBLIC_URL}/Section2.png`

const SectionTwo: React.FC = () => {
  return (
    <div id="two_e_51">
      <div id="two_e_52">
        <img id="two_e_54" alt="Section 2" src={image} />
        <div id="two_e_57">
          <div id="two_e_58">
            <p id="two_e_59">2</p>
          </div>
        </div>
        <div id="two_e_53">
          <p id="two_e_56">Customize for your app</p>
          <p id="two_e_55">
            The Repaint editor lets you adjust everything at the code level,
            including css styles, tree structure, visual assets, and text. The
            editor is developer-first.
          </p>
        </div>
      </div>
      <div id="two_e_99"></div>
    </div>
  )
}

export { SectionTwo }
