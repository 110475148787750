import { useForm } from '@formspree/react'
import './CTA.css'

const CTA: React.FC = () => {
  const [state, handleSubmit] = useForm('xpwzagby')

  return (
    <div id="cta_e_21">
      <div id="cta_e_22">
        <div id="cta_e_24">
          <p id="cta_e_27">Get notified when Repaint is live</p>
          <p id="cta_e_26">
            Right now, we’re only showing Repaint to beta users. Join the
            waitlist to get notified when Repaint is open to the public.
          </p>
        </div>
        {state.succeeded ? (
          <div id="cta_e_121">
            <p id="cta_e_120">Thank you, we will reach out to you soon!</p>
          </div>
        ) : (
          <form
            id="cta_e_69"
            name="signup"
            method="POST"
            data-netlify="true"
            onSubmit={handleSubmit}
          >
            <input
              id="cta_e_70"
              name="email"
              type="email"
              placeholder="Email"
            />
            <button id="cta_e_72" type="submit">
              <p id="cta_e_71">Submit</p>
            </button>
          </form>
        )}
      </div>
    </div>
  )
}

export { CTA }
